import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import SEO from '../components/seo';
// images
import WhyImg1 from '../images/why1.jpg';
import WhyImg2 from '../images/why2.jpg';
import '../styles/app.scss';

const schedule = ({ data }) => {
    const { wordpressPage: post } = data;
    console.log(post);
    const test_title = post.yoast_title;
    const new_test_title = test_title.replace('&#039;', "'");
    const inlineCss = {
        maxWidth: '600px',
        marginTop: '50px',
    };

    console.log(post.yoast_json_ld[0].wordpress__graph[1].description);

    return (
        <>
            <SEO
                title={new_test_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <section className="page-section smallestwdt centersec">
                <div className="container">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: post.content,
                        }}
                    />

                    
                    <a
                        className="wrtsbtn yellowbtn healcode-register"
                        style={inlineCss}
                        href="https://clients.mindbodyonline.com/ASP/su1.asp?studioid=524662&tg=&vt=&lvl=&stype=-98&view=&trn=0&page=&catid=&prodid=&date=4%2f9%2f2021&classid=0&prodGroupId=&sSU=&optForwardingLink=&qParam=&justloggedin=&nLgIn=&pMode=0&loc=1"
                        target="_blank"
                    >
                        To sign up for our upcoming Classes/Special Events -
                        Click Here
                    </a>
					
                </div>
            </section>           
            <Footer />
        </>
    );
};

export default schedule;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            content
            acf {
                schedule_iframe_src
                story_time_title
                story_time_content
                story_time_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                music_classes_title
                music_classes_content
                music_classes_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
